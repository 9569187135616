import swal from "sweetalert";

export const confirmPopup = (title, text, func) => {
    swal({
        title: title,
        text: text,
        icon: "warning",
        buttons:["Cancel","OK"],
        dangerMode: true,
        closeOnClickOutside: false
    })
        .then(willDelete => {
            if (willDelete) {
                func();
            }
        });
}