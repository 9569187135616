// react components
import { useEffect, useContext } from 'react';

// installed packages
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard
} from 'mdb-react-ui-kit';

// user components
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import { common } from '../components/common';
import Title from '../components/title';
import Footer from '../components/footer';
import AlarmStatus from '../components/dashboard/alarmStatus/alarmStatus';
import Map from '../components/layout/map';

export default function Layout() {

    const {
        showMenu,
        contextHolder
    } = useContext(common);


    return (
        <MDBContainer className='h-100' fluid style={{ backgroundColor: "#eceff1" }}>
            {contextHolder}
            <MDBRow className='h-100'>
                {
                    showMenu ?
                        <MDBCol lg="1" className='h-100 d-none d-lg-block d-xl-block'>
                            <Sidebar name="layout" />
                        </MDBCol> : null
                }
                <MDBCol lg={showMenu ? "11" : "12"} className='py-2 px-3 h-100'>
                    <Navbar />
                    <Title title={"layout"} />
                    <MDBRow className='mx-0' style={{ height: "85%" }}>
                        <MDBCol lg="9" className='h-100 px-2 pb-2'>
                            <Map />
                        </MDBCol>
                        <MDBCol lg="3" className='h-100 px-2 pb-2'>
                            <AlarmStatus />
                        </MDBCol>
                    </MDBRow>
                    <Footer />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}
