// react hooks
import { useContext, useEffect, useState } from 'react';

// installed packages
import { useNavigate } from 'react-router-dom';
import {
    MDBCol,
    MDBBtn,
    MDBTypography
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faRightToBracket, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import {
    Checkbox,
    message,
    ConfigProvider,
} from 'antd';
import axios from 'axios';

// created components
import { common } from '../common';

// configuration files
// import URL from '../../configuration/url.json';

function Form(props) {

    const {
        primaryColor
    } = useContext(common);

    const redirect = useNavigate();

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    // method to validate input fields
    const validate = () => {
        if (username !== "" && password !== "") {
            Login();
        } else if (username === "") {
            message.error("Please Enter Username!")
        } else if (password === "") {
            message.error("Please Enter Password!")
        }
    }

    // method for login 
    async function Login() {
        try {
            if (username === "Hawfinch" && password === "Admin@2024") {
                message?.success("Logged In Successfully!");
                localStorage.setItem('userName', username);
                setTimeout(() => {
                    redirect("/layout");
                }, 500);
            } else {
                message?.error("Incorrect username or password!");
            }
        } catch (error) {
            console.error(error?.message);
        }
    }


    useEffect(() => {
        if (localStorage.getItem('userName') !== null) {
            redirect("/dashboard");
        }
    }, [])

    return (
        <div className='px-2'>
            <div className='d-flex mb-2'>
                <FontAwesomeIcon
                    icon={faRightToBracket}
                    className='mb-2 me-2'
                    style={{
                        color: "#fff",
                        height: "12%",
                        width: "12%"
                    }}
                />
                <MDBTypography
                    tag="h1"
                    className='fw-bold text-light'
                >
                    Welcome!
                </MDBTypography>
            </div>
            <div className='pt-2 mb-1'>
                <label className='fw-bold ms-2 text-light mb-1'>
                    <FontAwesomeIcon icon={faUser} className='me-2' />
                    Username
                </label>
                <input
                    type='text'
                    className='form-control form-control-lg rounded-8 shadow-0 border-0 w-100'
                    maxLength={30}
                    placeholder='Enter Username'
                    value={username}
                    onChange={(e) => setUserName(e?.target?.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            validate();
                        }
                    }}
                />
            </div>
            <div className='pt-2 mb-1'>
                <label className='fw-bold ms-2 text-light mb-1'>
                    <FontAwesomeIcon icon={faKey} className='me-2' />
                    Password
                </label>
                <input
                    type={isShowPassword ? 'text' : 'password'}
                    className='form-control form-control-lg rounded-8 shadow-0 border-0 w-100'
                    placeholder='Enter Password'
                    value={password}
                    onChange={(e) => setPassword(e?.target?.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            validate();
                        }
                    }}
                />
            </div>
            <div>
                <ConfigProvider
                    theme={{
                        token: {
                            colorText: "#fff",
                            fontWeightStrong: true
                        }
                    }}>
                    <Checkbox
                        className='align-self-start ms-2 mt-2'
                        onChange={() => setIsShowPassword((p) => !p)}
                    >
                        Show Password
                    </Checkbox>
                </ConfigProvider>
            </div>
            <div className='px-1 text-center'>
                <MDBBtn
                    rounded
                    outline
                    size='md'
                    color='light'
                    className='fw-bold shadow-0 mt-1'
                    onClick={validate}
                >
                    Login
                </MDBBtn>
            </div>
        </div>
    )
}

export default Form;