// react components
import { useContext } from "react";

// installed packages
import { MDBTypography } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faAnglesLeft,
    faCircleUser,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

// user components
import { common } from "./common";
import { confirmPopup } from "./confirmAlert";
import { MDBSpinner } from 'mdb-react-ui-kit';

// configuration files

export default function Title(props) {

    const {
        showMenu,
        setShowMenu,
        primaryColor,
        mqttConn
    } = useContext(common);

    const redirect = useNavigate();

    const logOut = () => {
        localStorage.removeItem("userName");
        redirect("/");
    }

    const items = [
        {
            key: '1',
            label: (
                <p className="fw-bold my-n1" onClick={() => confirmPopup("Logout?", "Are You Sure want to logout?", logOut)}>
                    <FontAwesomeIcon icon={faRightFromBracket} className="text-danger me-1" />
                    Logout?
                </p>
            ),
        }
    ]

    return (
        <div className="d-flex justify-content-between">
            <div className="d-none d-lg-flex w-100 align-items-center ">
                <Tooltip title={showMenu ? "Hide Menu" : "Show Menu"} placement="bottom">
                    <FontAwesomeIcon
                        icon={showMenu ? faAnglesLeft : faAnglesRight}
                        className="mx-2 my-2 pt-1 fw-bold"
                        onClick={() => { setShowMenu((p) => !p) }}
                        style={{ color: primaryColor, fontSize: "1vw", cursor: "pointer" }}
                    />
                </Tooltip>
                <MDBTypography tag='h4'
                    className={"fw-bold my-2 pt-1 text-capitalize"}
                    style={{ fontSize: "1vw" }}
                >
                    {props?.title}
                </MDBTypography>
            </div>
            <div className="d-flex d-sm-none d-lg-none w-100 align-items-center">
                <Tooltip title={showMenu ? "Hide Menu" : "Show Menu"} placement="bottom">
                    <FontAwesomeIcon
                        icon={showMenu ? faAnglesLeft : faAnglesRight}
                        className="mx-2 my-2 pt-1 fw-bold"
                        onClick={() => { setShowMenu((p) => !p) }}
                        style={{ color: primaryColor, fontSize: "4vw", cursor: "pointer" }}
                    />
                </Tooltip>
                <MDBTypography tag='h4'
                    className={"fw-bold my-2 pt-1 text-capitalize"}
                    style={{ fontSize: "4vw" }}
                >
                    {props?.title}
                </MDBTypography>
            </div>

            <div className="px-0 d-none d-lg-flex  align-items-center py-2">
                <Tooltip title={mqttConn ? 'IO server connected!' : 'IO server disconnected!'} placement="left">
                    <MDBSpinner grow color={mqttConn ? 'success' : 'danger'} size="sm" className="me-1">
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </Tooltip>
                <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
                    <div className="px-1 py-1 rounded-8 d-flex" style={{ backgroundColor: primaryColor, cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faCircleUser} className="text-light me-1" style={{ fontSize: "1.3vw" }} />
                        <span className="fw-bold text-light me-1" style={{ fontSize: "0.8vw" }} >Username</span>
                    </div>
                </Dropdown>
            </div>
            <div className="px-0 d-flex d-sm-none d-lg-none align-items-center py-2">
                <Tooltip title={mqttConn ? 'IO server connected!' : 'IO server disconnected!'} placement="left">
                    <MDBSpinner grow color={mqttConn ? 'success' : 'danger'} size="sm" className="me-1">
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                </Tooltip>
                <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
                    <div className="px-1 py-1 rounded-8 d-flex" style={{ backgroundColor: primaryColor, cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faCircleUser} className="text-light me-1" style={{ fontSize: "4vw" }} />
                        <span className="fw-bold text-light me-1" style={{ fontSize: "2.5vw" }} >Username</span>
                    </div>
                </Dropdown>
            </div>
        </div>
    );
}