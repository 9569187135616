export function mqttConnAndSubs(client, setMqttConn, mqttTopics) {
  client.on("connect", () => {
    console.log("mqtt connected!");
    setMqttConn(true);
    for (const d of mqttTopics) {
      client.subscribe(d, { qos: 2 });
    }
  });
  client.on('disconnect', function (packet) {
    console.log(packet);
    setMqttConn(false);
  })
}