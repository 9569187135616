import { MDBCard, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import Form from "../components/login/form";
import Banner from "../components/login/banner";
import { useContext } from "react";
import { common } from "../components/common";

export default function Login() {

    const { primaryColor } = useContext(common);

    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <MDBCard className="rounded-8 w-50" style={{ height: "45%", minWidth: "280px", minHeight: "400px" }}>
                <MDBRow className="h-100 rounded-8 mx-2 my-2">
                    <MDBCol lg="7" className="h-100 d-none d-lg-flex justify-content-center align-items-center">
                        <Banner />
                    </MDBCol>
                    <MDBCol lg="5" className="h-100 d-flex justify-content-center align-items-center rounded-8 rou1nded-start"
                        style={{ backgroundImage: `linear-gradient(to right, ${primaryColor} , ${primaryColor}` }}
                    >
                        <Form />
                    </MDBCol>
                </MDBRow>
            </MDBCard>
        </div>
    );

}