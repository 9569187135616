// react hooks
import { useContext } from 'react';

// installed packages
import {
    MDBCol, MDBTypography
} from 'mdb-react-ui-kit';

// created components
import { common } from '../common';

// images
import Logo from '../../assets/HT_logo_standard.png';
import fire from '../../assets/fire_Iot.png';

export default function Banner(props) {

    const {
        primaryColor
    } = useContext(common);

    return (
        <div
            className='h-100 d-none d-lg-block section rounded-8 rounded-start mx-0 py-2'
        >
            <div
                className='d-flex flex-column justify-content-center align-items-center h-100 w-100 section rounded-8 rounded-start'>
                <img src={Logo} alt="Hawfinch" style={{height:"15%"}}/>
                <MDBTypography
                    tag="h2"
                    className='fw-bold mt-2'
                    style={{ color: primaryColor }}
                >
                    Fire Detection System
                </MDBTypography>
            </div>
        </div>
    )
}
