import SectionTitle from "../sectionTitle";
import dashbaordDt from "../../config/dashboard.json";
import { useContext, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faHouseFire } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'antd';
import { common } from "../common";
import BluePrint from "../../assets/blueprint.jpg";

export default function Map() {

    const {
        client
    } = useContext(common);

    const ref = useRef(null);

    const [data, setData] = useState({});
    const [sensor1, setSensor1] = useState("");
    const [sensor2, setSensor2] = useState("");
    const [param1, setParam1] = useState("");
    const [param2, setParam2] = useState("");

    // data construction
    const dataConstruction = (topic, msg) => {
        if (topic === "sensor1") {
            setSensor1(msg.toString());
        } else if (topic === "sensor2") {
            setSensor2(msg.toString());
        } else if (topic === "param1") {
            setParam1(msg.toString());
        } else if (topic === "param2") {
            setParam2(msg.toString());
        }
    }

    let i = 0;
    useEffect(() => {
        setData(dashbaordDt);
        if (client && i === 0) {
            let isMounted = true;
            client.on('message', (topic, msg) => isMounted ? dataConstruction(topic, msg) : null);
            return () => { isMounted = false };
        }
        i++;
    }, []);

    const icons = (e, sensor, k) => {
        const icon = sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
            faHouseFire :
            sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                faHouseFire :
                sensor?.["type name"] === "param1" && param1 === "true" ?
                    faHouseFire :
                    sensor?.["type name"] === "param2" && param2 === "true" ?
                        faHouseFire :
                        faHouse;
        return <div className={`position-relative px-1 my-2 ${icon?.iconName === "house-fire" ? "zoomPulse" : ""}`} key={k}>
            <p
                className="position-absolute fw-bold text-light text-capitalize start-0 ps-2"
                style={{ fontSize: ".7vw" }}
            >
                {e}
            </p>
            <FontAwesomeIcon
                className="text-light border border-dark px-3 py-4 rounded-5"
                icon={icon}
                style={{
                    fontSize: "2vw",
                    backgroundImage: sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                        `linear-gradient(to left, #C62828 , red)` :
                        sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                            `linear-gradient(to left, #C62828 , red)` :
                            sensor?.["type name"] === "param1" && param1 === "true" ?
                                `linear-gradient(to left, #C62828 , red)` :
                                sensor?.["type name"] === "param2" && param2 === "true" ?
                                    `linear-gradient(to left, #C62828 , red)` :
                                    `linear-gradient(to left, #2E7D32 , #8BC34A)`
                }}
            />
            <p
                className="position-absolute start-50 translate-middle text-center fw-bold text-light text-capitalize"
                style={{ fontSize: ".9vw", top: "85%", left: "25%" }}
            >
                {sensor?.["alias name"]}
            </p>
        </div>
    }

    return (
        <div className="h-100 bg-light rounded-5">
            <SectionTitle subTitle={"blue print"} />
            <div className="w-100 px-2 py-2" style={{ height: "90%" }}>
                <div className="d-flex d-lg-flex d-xl-flex h-100 w-100 rounded-5 border mx-0 px-2 py-2"
                    style={{ backgroundColor: "#f5f5f5" }}
                >
                    <div
                        className="d-flex justify-content-center align-items-center h-100 w-100"
                    >
                        <svg
                            className="rounded-5 border border-dark h-100 w-100"
                            style={{
                                backgroundImage: `url(${BluePrint})`,
                                // height: "630px",
                                // width: "1230px",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                            ref={ref}
                        >
                            {
                                Object.keys(data)?.map((e, i) => {
                                    return data[e]?.map((sensor, index) => {
                                        return <Tooltip title={() => icons(e, sensor, index)} color={"#fff"} trigger="click" open key={index}>
                                            <circle
                                                cx={sensor?.["type name"] === "sensor1" ?
                                                    "40%" :
                                                    sensor?.["type name"] === "sensor2" ?
                                                        "85%" :
                                                        sensor?.["type name"] === "param1" ?
                                                            "23%" : "65%"}
                                                cy={sensor?.["type name"] === "sensor1" ?
                                                    "35%" :
                                                    sensor?.["type name"] === "sensor2" ?
                                                        "37%" :
                                                        sensor?.["type name"] === "param1" ?
                                                            "70%" : "80%"}
                                                r={10}
                                                fill={sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                                    "red" :
                                                    sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                                        "red" :
                                                        sensor?.["type name"] === "param1" && param1 === "true" ?
                                                            "red" :
                                                            sensor?.["type name"] === "param2" && param2 === "true" ?
                                                                "red" :
                                                                "#8BC34A"}
                                                stroke={sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                                    "#B71C1C" :
                                                    sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                                        "#B71C1C" :
                                                        sensor?.["type name"] === "param1" && param1 === "true" ?
                                                            "#B71C1C" :
                                                            sensor?.["type name"] === "param2" && param2 === "true" ?
                                                                "#B71C1C" :
                                                                "#1B5E20"}
                                                strokeWidth={2} />
                                        </Tooltip>
                                    })
                                })
                            }
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}