import mqtt from "mqtt"; // import namespace "mqtt"
// const client = mqtt.connect("ws://test.mosquitto.org:8080/");


export default function Test() {

    // client.on("connect", () => {
    //     client.subscribe("zone1", (err) => {
    //         if (!err) {
    //             client.publish("zone1", "Hello mqtt");
    //         }
    //     });
    // });

    // client.on("message", (topic, message) => {
    //     // message is Buffer
    //     console.log(message.toString());
    //     client.end();
    // });

    return <p className="fw-bold fs-4" >Test</p>
}