export default function Footer() {
    return (
        <div
            className="fw-bold text-center text-muted d-flex justify-content-center align-items-center pt-3"

            style={{ height: "4%" }}
        >
            <p
                id="footer"
                className="pt-2 d-none d-lg-block"
                onClick={() => window.open('https://hawfinch.in', "_blank")}
                style={{ fontSize: ".9vw" }}
            >
                Copyright © 2024 | <span>Hawfinch Technologies</span>
            </p>
        </div >
    )
}