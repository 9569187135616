import './style/style.css';

// react hooks
import { useState, useEffect } from 'react';

// installed packages
import { HashRouter, Routes, Route } from 'react-router-dom';
import mqtt from 'mqtt';
import { notification } from 'antd';

// components
import Login from './pages/login';
import { common } from "./components/common";
import Dashboard from './pages/dashboard';
import { mqttConnAndSubs } from './components/mqtt/connection';
import Test from './pages/test';

// configuration
import URL from "./config/config.json";
import Layout from './pages/layout';

const getClientId = () => `mqttjs_ + ${Math.random().toString(16).substr(2, 8)}`;

const mqttConnStr = mqtt.connect("mqtt://test.mosquitto.org:1883", {
  getClientId,
  keepalive: 60,
  clean: true,
  reconnectPeriod: 300000,
  connectTimeout: 30000,
  rejectUnauthorized: false,
  username: "",
  password: "",
  protocolId:'MQTT',
  // port:1883
  port: 8080
});

function App() {

  const [api, contextHolder] = notification.useNotification();

  const [primaryColor, setPrimaryColor] = useState("#FF9800");
  const [showMenu, setShowMenu] = useState(true);
  const [mqttConn, setMqttConn] = useState(false);
  const [client, setClient] = useState(mqttConnStr);
  const [mqttTopics, setMqttTopics] = useState(URL?.mqtt?.topics);

  let i = 0;
  useEffect(() => {
    if (i === 0) {
      mqttConnAndSubs(client, setMqttConn, mqttTopics);
    }
    i++
  }, [])

  return (
    <HashRouter>
      <common.Provider value={{
        primaryColor, setPrimaryColor,
        showMenu, setShowMenu,
        client, setClient,
        mqttConn, setMqttConn,
        api, contextHolder
      }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/layout" element={<Layout />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </common.Provider>
    </HashRouter>
  );
}

export default App;
