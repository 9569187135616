// react hooks
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// installed packages
import { MDBBtn, MDBTypography } from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartPie,
    faCircleCheck,
    faExpand,
    faFilePen,
    faMicrochip,
    faSitemap,
    faSliders,
    faWrench,
    faCodeBranch
} from '@fortawesome/free-solid-svg-icons';

// user components
import { common } from "./common";

// configuration files
import attr from "../config/attr.json";

export default function Sidebar(props) {

    const {
        primaryColor,
        showMenu,
        setShowMenu
    } = useContext(common);

    const list = attr?.sidebar;

    return (
        <div className="vh-100 bg-light px-2 mx-n3" id="sidebar">
            <div className="py-2">
                <MDBTypography
                    tag="h1"
                    className="fw-bold text-center"
                    style={{ color: primaryColor, fontSize: "1.5vw" }}
                >
                    FDS
                </MDBTypography>
                <p
                    className="fw-bold text-center mb-n3 mt-n2"
                    style={{ color: primaryColor, fontSize: ".7vw" }}
                >
                    V 1.0.0
                </p>
                <hr></hr>
                <div>

                    {
                        list.map((e, i) => {
                            return <Link
                                to={"/" + e}
                                key={i}
                                className={`px-0 mx-n2 py-2 rounded-0 text-center mb-2 d-flex`}
                                id="link"
                                style={props?.name === e ?
                                    { backgroundColor: "#ECEFF1", color: primaryColor, fontSize: "0.9vw" } :
                                    { color: "#000", fontSize: "0.9vw" }
                                }>
                                <FontAwesomeIcon
                                    icon={
                                        e === "dashboard" ?
                                            faChartPie :
                                            e === "layout" ?
                                                faSitemap :
                                                e === "manual" ?
                                                    faSliders :
                                                    e === "master" ?
                                                        faMicrochip :
                                                        e === "report" ?
                                                            faFilePen :
                                                            e === "settings" ?
                                                                faWrench :
                                                                e === "softwareVerification" ?
                                                                    faCircleCheck :
                                                                    e === "iniSettings" ?
                                                                        faCodeBranch : faExpand
                                    }
                                    className="mx-2 rounded-5"
                                    style={{
                                        color: primaryColor,
                                        fontSize: "1.5vw"
                                    }}
                                />
                                <MDBTypography
                                    tag="p"
                                    className="fw-bold text-center mb-n1 text-capitalize"
                                    style={{ fontSize: "1vw" }}
                                >
                                    {
                                        e === "programConfig" ?
                                            "program" :
                                            e === "roi" ?
                                                "ROI" :
                                                e === "softwareVerification" ?
                                                    "check" :
                                                    e === "iniSettings" ?
                                                        "Parameter" : e
                                    }
                                </MDBTypography>
                            </Link>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

