// importing react components
import '../App.css'
import React, { useState, useContext, useEffect } from 'react';

// importing installed packages
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBTypography,
} from 'mdb-react-ui-kit';


// importing components
import { common } from './common';

// importing images
import Logo from '../assets/HT_logo_standard.png';
import { useNavigate } from 'react-router-dom';

// importing config files

export default function Navbar(props) {

    const {
        primaryColor, setShowMenu
    } = useContext(common);

    const reDirect = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("userName") === null || localStorage.getItem("userName") === undefined) {
            reDirect("/")
        }
    }, [])

    return (
        <MDBNavbar expand='lg' className={'rounded-6 mt-1 shadow-0 bg-light'} light style={{ height: "6%" }}>
            <MDBContainer fluid>
                <MDBNavbarBrand href='#'>
                    <MDBTypography
                        tag="h3"
                        className='fw-bold pt-2 mx-2 d-none d-lg-block'
                        style={{ color: primaryColor, fontSize: "1.5vw" }}
                    >
                        Fire Detection System
                    </MDBTypography>
                    <MDBTypography
                        tag="h3"
                        className='fw-bold mx-2 mt-n1 d-block d-sm-none d-lg-none'
                        style={{ color: primaryColor }}
                    >
                        FMS
                    </MDBTypography>
                </MDBNavbarBrand>
                <div className='d-flex justify-content-end'>
                    <img src={Logo} className="mx-2 d-none d-lg-block" style={{ height: "2.2vw" }} />
                    <img src={Logo} className="mx-2 mt-n2 d-block d-sm-none d-lg-none" style={{ height: "30px" }} />
                </div>
            </MDBContainer>
        </MDBNavbar>
    );
}

