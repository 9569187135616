import { faBell, faMapLocation, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { common } from "./common";

export default function SectionTitle(props) {
    const {
        primaryColor
    } = useContext(common);

    const symbol = props?.subTitle === "sensor list" ?
        faSitemap :
        props?.subTitle === "blue print" ?
            faMapLocation : faBell;

    return (
        <div>
            <h4
                className="fw-bold d-none d-lg-block text-capitalize pt-3 pb-2 px-3"
                style={{ fontSize: "1vw" }}
            >
                <FontAwesomeIcon
                    icon={symbol}
                    className="me-1"
                    style={{ color: primaryColor }}
                />
                {props?.subTitle}
            </h4>
            <h4
                className="fw-bold d-block d-sm-none d-lg-none text-capitalize pt-3 pb-2 px-3"
                style={{ fontSize: "3.5vw" }}
            >
                <FontAwesomeIcon
                    icon={symbol}
                    className="me-1"
                    style={{ color: primaryColor }}
                />
                {props?.subTitle}
            </h4>
        </div>
    )
}