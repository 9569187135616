import { useContext, useState, useEffect } from "react";
import SectionTitle from "../../sectionTitle";
import { common } from "../../common";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { openNotification } from "../../notification";
import { MDBTable, MDBTableBody } from "mdb-react-ui-kit";

export default function AlarmStatus() {

    const {
        client,
        primaryColor,
        api
    } = useContext(common);

    const [alarmList, setAlarmList] = useState([]);
    const [trigger, setTrigger] = useState(false);

    // construct data

    const dataConstruction = (topic, msg) => {
        let message = msg.toString();
        let uniqueId = Math.random()?.toString();
        if (message === "true") {
            if (alarmList.filter(item => item?.["id"] === uniqueId).length === 0) {
                openNotification(api, "error", `${topic} Alarm`, `Check ${topic}`)
                setAlarmList((d) => d.concat({
                    "id": uniqueId,
                    "alarm name": topic,
                    "alarm state": message,
                    "start time": moment().format("HH:mm:ss"),
                    "end time": "-"
                }))
                setTrigger((p) => !p);
            }
        } else if (message === "false") {
            setAlarmList((d) => {
                d?.map((e, i) => {
                    if (e?.["alarm name"] === topic) {
                        e["alarm state"] = message;
                        e["end time"] = moment().format("HH:mm:ss");
                    }
                });
                return d
            });
            setTrigger((p) => !p);
        }
    }

    let i = 0;
    useEffect(() => {
        if (client && i === 0) {
            let isMounted = true;
            client.on('message', (topic, msg) => isMounted ? dataConstruction(topic, msg) : null);
            return () => { isMounted = false };
        }
        i++
    }, []);

    useEffect(() => { }, [trigger]);

    return (
        <div className="h-100 bg-light rounded-5">
            <SectionTitle subTitle={"alarm status"} />
            <div className="w-100 px-2 py-2" style={{ height: "90%" }}>
                <div className="h-100 w-100 rounded-5 border px-3 py-3" style={{ backgroundColor: "#f5f5f5", overflowY: "scroll" }}>
                    {
                        alarmList?.reverse()?.map((e, i) => {
                            return (
                                <div
                                    key={i}
                                    className={`px-2 py-2 w-100 rounded-5 d-flex align-items-center
                                         ${e?.["alarm state"] === "true" ? "blink text-light" : "text-dark border"} mb-2`}
                                    style={{ height: "23%", backgroundColor: e?.["alarm state"] === "true" ? "red" : "#fff" }}
                                >

                                    <div>
                                        <h6 className="fw-bold text-capitalize pt-2 ps-2" style={{ fontSize: "1.25vw" }}>
                                            <FontAwesomeIcon
                                                icon={faCircleExclamation}
                                                className="me-2 mt-2"
                                                style={{ fontSize: "1.5vw", color: e?.["alarm state"] === "true" ? "#fff" : "red" }}
                                            />
                                            Fire Alert!
                                        </h6>
                                        <MDBTable borderless>
                                            <MDBTableBody className={`fw-bold text-${e?.["alarm state"] === "true" ? "light" : "#212121"} text-capitalize`}>
                                                <tr>
                                                    <td className="p-0 ps-3">Zone</td>
                                                    <td className="p-0 ps-3">{e?.["topic"]}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0 ps-3">Sensor</td>
                                                    <td className="p-0 ps-3">{e?.["alarm name"]}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0 ps-3">Start time</td>
                                                    <td className="p-0 ps-3">{e?.["start time"]}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0 ps-3">End time</td>
                                                    <td className="p-0 ps-3">{e?.["end time"]}</td>
                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>

                                </div>
                            )
                            return (
                                <div
                                    key={i}
                                    className="px-2 py-2 w-100 rounded-5 text-light d-flex align-items-center blink mb-2"
                                    style={{ height: "8%", backgroundColor: "red" }}
                                >
                                    <FontAwesomeIcon icon={faCircleExclamation} style={{ height: "90%", width: "15%" }} />
                                    <h5 className="fw-bold text-capitalize pt-2">Check {e?.["alarm name"]}</h5>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}