import SectionTitle from "../../sectionTitle";
import dashbaordDt from "../../../config/dashboard.json";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faHouseFire } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from 'antd';
import { common } from "../../common";
import { MDBRow } from "mdb-react-ui-kit";

export default function SensorList() {

    const {
        client
    } = useContext(common);

    const [data, setData] = useState({});
    const [sensor1, setSensor1] = useState("");
    const [sensor2, setSensor2] = useState("");
    const [param1, setParam1] = useState("");
    const [param2, setParam2] = useState("");

    // data construction
    const dataConstruction = (topic, msg) => {
        if (topic === "sensor1") {
            setSensor1(msg.toString());
        } else if (topic === "sensor2") {
            setSensor2(msg.toString());
        } else if (topic === "param1") {
            setParam1(msg.toString());
        } else if (topic === "param2") {
            setParam2(msg.toString());
        }
    }

    let i = 0;
    useEffect(() => {
        setData(dashbaordDt);
        if (client && i === 0) {
            let isMounted = true;
            client.on('message', (topic, msg) => isMounted ? dataConstruction(topic, msg) : null);
            return () => { isMounted = false };
        }
        i++;
    }, []);

    return (
        <div className="h-100 bg-light rounded-5">
            <SectionTitle subTitle={"sensor list"} />
            <div className="w-100 px-2 py-2" style={{ height: "90%" }}>
                <div className="d-none d-lg-flex d-xl-flex h-100 w-100 rounded-5 border mx-0 px-2 py-2"
                    style={{ backgroundColor: "#f5f5f5" }}
                >
                    {
                        Object.keys(data)?.map((e, i) => {
                            return data[e]?.map((sensor, index) => {
                                const icon = sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                    faHouseFire :
                                    sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                        faHouseFire :
                                        sensor?.["type name"] === "param1" && param1 === "true" ?
                                            faHouseFire :
                                            sensor?.["type name"] === "param2" && param2 === "true" ?
                                                faHouseFire :
                                                faHouse
                                return <div
                                    key={index}
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "25%" }}
                                >
                                    <div className="position-relative px-1 mt-n5 ">
                                        <p
                                            className="position-absolute fw-bold text-light text-capitalize start-0 ps-2"
                                            style={{ fontSize: ".75vw" }}
                                        >
                                            {e}
                                        </p>
                                        <Tooltip title={sensor?.["sensor name"]} placement="bottom" key={index}>
                                            <FontAwesomeIcon
                                                className="text-light border border-dark px-3 py-4 rounded-5"
                                                icon={icon}
                                                style={{
                                                    fontSize: "3.5vw",
                                                    backgroundImage: sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                                        `linear-gradient(to left, #C62828 , red)` :
                                                        sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                                            `linear-gradient(to left, #C62828 , red)` :
                                                            sensor?.["type name"] === "param1" && param1 === "true" ?
                                                                `linear-gradient(to left, #C62828 , red)` :
                                                                sensor?.["type name"] === "param2" && param2 === "true" ?
                                                                    `linear-gradient(to left, #C62828 , red)` :
                                                                    `linear-gradient(to left, #2E7D32 , #8BC34A)`
                                                }}
                                            />
                                        </Tooltip>
                                        <p
                                            className="position-absolute start-50 translate-middle text-center fw-bold text-light text-capitalize"
                                            style={{ fontSize: ".90vw", top: "90%", left: "25%" }}
                                        >
                                            {sensor?.["alias name"]}
                                        </p>
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>

                {/* mobile */}
                <div className="d-flex align-content-start flex-wrap d-sm-none d-lg-none h-100 w-100 rounded-5 border mx-0 px-2 py-2"
                    style={{ backgroundColor: "#f5f5f5" }}
                >
                    {
                        Object.keys(data)?.map((e, i) => {
                            return data[e]?.map((sensor, index) => {
                                const icon = sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                    faHouseFire :
                                    sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                        faHouseFire :
                                        sensor?.["type name"] === "param1" && param1 === "true" ?
                                            faHouseFire :
                                            sensor?.["type name"] === "param2" && param2 === "true" ?
                                                faHouseFire :
                                                faHouse
                                return <div
                                    key={index}
                                    className="d-flex justify-content-center align-items-center px-1"
                                    style={{ height: "25%", width: "50%" }}
                                >
                                    <div className="position-relative px-1 mb-2 ">
                                        <p
                                            className="position-absolute fw-bold text-light text-capitalize start-0 ps-2"
                                            style={{ fontSize: "3vw" }}
                                        >
                                            {e}
                                        </p>
                                        <FontAwesomeIcon
                                            className="text-light border border-dark px-3 py-4 rounded-5"
                                            icon={icon}
                                            style={{
                                                fontSize: "15vw",
                                                backgroundImage: sensor?.["type name"] === "sensor1" && sensor1 === "true" ?
                                                    `linear-gradient(to left, #C62828 , red)` :
                                                    sensor?.["type name"] === "sensor2" && sensor2 === "true" ?
                                                        `linear-gradient(to left, #C62828 , red)` :
                                                        sensor?.["type name"] === "param1" && param1 === "true" ?
                                                            `linear-gradient(to left, #C62828 , red)` :
                                                            sensor?.["type name"] === "param2" && param2 === "true" ?
                                                                `linear-gradient(to left, #C62828 , red)` :
                                                                `linear-gradient(to left, #2E7D32 , #8BC34A)`
                                            }}
                                        />
                                        <p
                                            className="position-absolute start-50 translate-middle text-center fw-bold text-light text-capitalize"
                                            style={{ fontSize: "3vw", top: "90%", left: "25%" }}
                                        >
                                            {sensor?.["alias name"]}
                                        </p>
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </div>
    )
}